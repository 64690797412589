import React from "react";
import "./Home.css";
function Home() {
  const alignRight = true;

  const navigate=()=>{
    window.location.assign('https://kite.zerodha.com/connect/login?v=3&api_key=0wk97q8ii3u1mnx5');
  }
  return (
    <div>
      <button type="button" className="btn btn-info login-btn" onClick={()=>navigate()}>
      Login Using Zerodha
        </button>
    </div>
  );
}

export default Home;
