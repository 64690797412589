import React from "react";
import "./Trigger.css";
import { useSearchParams } from "react-router-dom";
import { sha256, sha224 } from "js-sha256"
import axios from "axios";

function Trigger() {
  let isRequestTokenFetched: boolean = false;
  let isCheckSumFetched,
    isAccessTokenFetch,
    isLTSEnvUpdated,
    isLTSBootstraped: boolean = false;
  const [searchParams] = useSearchParams();
  const apiKey = "0wk97q8ii3u1mnx5";
  const apiSecret = "7puulwhn1jjn3du8rhknnqnj09clveix";
  let requestToken = null;
  let checkSum;
  requestToken = searchParams.get("request_token")
    ? searchParams.get("request_token")
    : null;
  // const requestToken=searchParams.get("request_token")?;

  isRequestTokenFetched = requestToken ? true : false;
  checkSum = getCheckSum(apiKey, requestToken, apiSecret);
  isCheckSumFetched = checkSum ? true : false;
  const accessToken = getAccessToken(apiKey, requestToken, checkSum);
  isAccessTokenFetch = true;
  // updateLTSGrabberEnv(accessToken);
  const alignRight = true;
  console.log("requestToken", requestToken);
  console.log("checkSum", checkSum);
  console.log("accessToken", accessToken);
  setTimeout(async () => {
    // let data = await updateAccessToken(accessToken);
    isLTSEnvUpdated = true;
    // initiateLogin();
    isLTSBootstraped = true;
  }, 3000);
  return (
    <div>
      <div className="item-line">
        <h6>Fetching request_token</h6> &nbsp;&nbsp;
        {!isRequestTokenFetched ? (
          <div className="spinner-border text-info my-spinner" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="green"
            className="bi bi-check-lg"
            viewBox="0 0 16 16"
          >
            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
          </svg>
        )}
        {/* <div>
          
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="red"
            className="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </div> */}
      </div>
      <div className="item-line">
        <h6>Creating checksum</h6> &nbsp;&nbsp;
        {!isCheckSumFetched ? (
          <div className="spinner-border text-info my-spinner" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="green"
            className="bi bi-check-lg"
            viewBox="0 0 16 16"
          >
            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
          </svg>
        )}
      </div>

      <div className="item-line">
        <h6>Fetching Access token</h6> &nbsp;&nbsp;
        {!isAccessTokenFetch ? (
          <div className="spinner-border text-info my-spinner" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="green"
            className="bi bi-check-lg"
            viewBox="0 0 16 16"
          >
            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
          </svg>
        )}
      </div>

      <div className="item-line">
        <h6>Updating lts-grabber env</h6> &nbsp;&nbsp;
        {!isLTSEnvUpdated ? (
          <div className="spinner-border text-info my-spinner" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="green"
            className="bi bi-check-lg"
            viewBox="0 0 16 16"
          >
            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
          </svg>
        )}
      </div>

      <div className="item-line">
        <h6>Bootstraping lts-grabber</h6> &nbsp;&nbsp;
        {isLTSBootstraped ? (
          <div className="spinner-border text-info my-spinner" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="green"
            className="bi bi-check-lg"
            viewBox="0 0 16 16"
          >
            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
          </svg>
        )}
      </div>

      <button type="button" className="btn btn-info">
        Show Log
      </button>
      <div className="card my-code">
        <code>
          is simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum
        </code>
      </div>
    </div>
  );
}

const getCheckSum = (
  apiKey: string,
  requestToken: string | null,
  apiSecret: string
) => {
  return sha256(apiKey + requestToken + apiSecret);
};

const getAccessToken = async (
  apiKey: string,
  requestToken: string | null,
  checkSUm: string
) => {
  let reqObj = {
    api_key: apiKey,
    request_token: requestToken,
    checksum: checkSUm,
  };
  let token: string = '';
  await axios
    .post("https://www.ltsgrabber.in/getMeAccessNow", reqObj)
    .then((res: any) => {
      console.log(res);
      console.log(res.data.data.access_token);
      token = res.data.data.access_token;
      updateAccessToken(token);
    });
  // return token;
  
};

const updateAccessToken = async (token:string) => {
  return await axios
    .post("https://www.ltsgrabber.in/updateToken", { access_token: token })
    .then((res: any) => {
      console.log(res);
      initiateLogin();
      return res;
    });
};

const initiateLogin = async () => {
  await axios.post("https://www.ltsgrabber.in/loginHook").then((res: any) => {
    console.log(res);
  });
};

export default Trigger;
